/* Your existing styles */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.drive-connect-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
}

.close-icon {
  cursor: pointer;
}

.drive-options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.drive-option {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.iconify {
  font-size: 24px;
  margin-right: 10px;
}

/* Media Queries for Mobile Support */

@media only screen and (max-width: 600px) {
  .drive-connect-modal {
    width: 80%; 
  }

  .drive-option {
    flex-direction: column;
    text-align: center; 
  }
}
