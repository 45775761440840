.container {
    top: 60px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.main-editor {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.data-grid-container {
    flex: 1;
    top: 60;
    width: 100%;
    height: 100%;
    bottom: 0;  
    overflow-x: auto;
}

@media screen and (max-width: 762px) {
    .container {
        width: 100vw;
        margin-left: 0;
    }
    .main-editor {
       width: 100%;
        height: 100%;
        overflow: hidden;
    }
    
    .data-grid-container {
        flex: 1;
        top:60;
        width: 100%;
        height: auto;
        bottom: 0;  
        overflow-x: auto;
    }
    
}
