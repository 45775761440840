/* features.css */
.feature-page {
    min-height: 100vh;
}

.main {
    padding-top: 100px;
}

.features-title {
    font-size: 2rem;
    padding: 1rem 0;
}

@media (max-width: 600px) {
    .features-title {
        font-size: 1.5rem;
        padding: 0.5rem 0;
    }
}
