body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
  }
  .divder{
    display: flex;
    flex-direction: row;
    width: 80%
  }
  .login-container {
    max-width: 400px;
    margin-top: 10%;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
  }
  
  .logo {
    max-width: 100%;
    height: 100px;
  }

  
  h2 {
    color: #333;
  }
  
  .login-form {
    margin-top: 20px;
  }
  
  .login-form button {
    width: 100%;
    padding: 15px;
    background-color:  #e33629;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .forgot-password {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  }
  
  .forgot-password a {
    color: #3498db;
    text-decoration: none;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }
  
  /* Media Queries for Mobile Support */
  
  @media only screen and (max-width: 600px) {
    .login-container {
      max-width: 100%;
      box-shadow: none;
      margin-top: 5px;
    }
    .logo {
      max-width: 100%;
      height: 100px;
    }
  }
  