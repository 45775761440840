* {
    margin: 0;
  }
  
  body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .signup-container {
    max-width: 300px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
  }
  .logo {
    max-width: 100%;
    height: auto;
  }
  
  h2 {
    color: #333;
  }
  
  .signup-form {
    margin-top: 20px;
  }
  
  
  .signup-form .signup-button {
    width: 100%;
    padding: 10px;
    background-color: #e33629;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .google-signup {
    display: flex;
    margin-top: 10px;
    background-color: transparent;
    padding: 3px;
    color: black;
    width: 80%;
    border-radius: 25px;
    border: 1px solid black;
  }
  
  .signup-links {
    margin-top: 20px;
  }
  
  .signup-links h5 {
    margin-bottom: 10px;
    color: #666;
  }
  
  .signup-links a {
    color: #3498db;
    text-decoration: none;
  }
  
  .signup-links a:hover {
    text-decoration: underline;
  }
  
  /* Media Queries for Mobile Support */
  
  @media only screen and (max-width: 600px) {
    .signup-container {
      max-width: 100%;
      box-shadow: none;
    }
    .signup-form button,
    .google-signup {
      width: 80%;
    }
    .logo {
      max-width: 100%;
      height: 100px;
    }
  }
  