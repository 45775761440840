/* Global styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.main {
  display: flex;
  flex-direction: row;
}

.main .sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.desktop-menu {
  padding: 20px 0; 
  flex-direction: column;
  display: flex;
  align-items: flex-start; /* Align items to the start */
}

.desktop-menu .menu-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  padding: 8px 16px; /* Consistent padding */
}

.desktop-menu .menu-item > span {
  color: black;
}

.brand {
  padding: 8px 16px; /* Consistent padding */
}

.new {
  padding: 8px 16px; /* Consistent padding */
  display: flex;
  color: black;
  cursor: pointer; /* Cursor pointer to indicate clickable */
}


/* Mobile adjustments */
.mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .main {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Full-width mobile view */
  }

  .mobile .header {
    display: flex;
    color: black;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%; /* Full-width header */
  }

  .mobile-menu {
    padding: 20px 0; /* Adjust padding as needed */
    flex-direction: column;
    display: flex;
    align-items: flex-start; /* Align items to the start */
  }

  .mobile-menu .menu-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    padding: 8px 16px; /* Consistent padding */
  }

  .mobile-menu .menu-item > span {
    display: flex;
    color: black;
  }

  .new {
    display: none;
  }

  .actions {
    display: flex;
    margin-top: 10px;
    align-items: center;
  }

  .actions > div {
    cursor: pointer;
    margin-left: 20px;
  }
}
