/* Non-Mobile Styles */
.feature-section {
    color: #2d2d2d;
    width: 100vw;
    display: flex;
    justify-content: center; /* Center the features container */
}

.features {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    padding: 80px;
    flex-wrap: wrap; /* Allow wrapping of items */
    max-width: 1200px; /* Restrict the maximum width for better centering */
    width: 100%; /* Ensure it takes full width available */
}

.feature {
    margin-bottom: 40px;
    width: 400px;
    height: auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.feature h5 {
    margin-bottom: 10px;
    font-size: 28px;
}

.feature p {
    font-size: 15px;
    padding: 10px;
}

/* Mobile Styles */
@media only screen and (max-width: 762px) {
    .feature-section {
        display: flex;
        position: relative;
        left: 50%;
        margin-top: 0;
        transform: translate(-50%, 0);
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .features {
        display: flex;
        flex-direction: column;
        align-items: center; 
        text-align: center;
        width: auto; /* Full width for better readability */
        padding: 0; /* Remove additional padding */
    }

    .feature {
        width: auto; /* Slightly less than 100% to add some padding from the edges */
        margin-bottom: 20px; /* Adjusted margin for better spacing */
        text-align: center;
        box-shadow: none;
    }

    .feature h5 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .feature p {
        font-size: 14px;
        padding: 5px;
    }
}
