.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 10px;
    bottom: 0;
    background-color: #f1f1f1;
    flex-wrap: wrap; 
  }
  
  .footer a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    margin: 0 10px;
  }
  
  .footer span {
    margin: 0 10px;
    color: #333;
  }
  
  /* Mobile devices */
  @media (max-width: 762px) {
    .footer {
      flex-direction: column;
      padding-left: 10px;
      bottom: 0;
      width: auto;
      align-items: flex-start;
    }

    .footer a {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  
    .footer span {
      margin-top: 10px;
    }
  }
  