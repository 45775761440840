/* CustomStyles.css */
.explorer {
    position: fixed;
    top: 60px;
    left: 65px;
    display: flex;
    height: 100vh;
    align-items: left;
    cursor: pointer;
    border: 1px solid black;
}

@media screen and (max-width: 768px) {
    .explorer {
        display: none;
    }
}



