/* header.css */

.header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
    right: 0;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    max-width: 100%;
    padding: 1rem;
    color: black;
  }
  
  .logo-container {
    flex: 0 0 auto;
  }
  
  .logo {
    height: auto;
    width: 100px;
  }
  
  .links {
    margin-right: 20px;
  }
  
  .links ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .links li {
    margin-right: 20px;
    font-weight: bold;
  }
  
  .links a {
    text-decoration: none;
    color: black;
  }
  
  .user-icon {
    display: flex;
    align-items: center;
    color: black;
    cursor: pointer;
  }
  
  .user-icon span {
    margin-left: 0.5rem;
  }
  
  .mobile-header {
    display: none;
  }
  
  @media screen and (max-width: 767px) {
    .header {
      display: none;
    }
  
    .mobile-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding-top: 10px;
      padding: 1rem;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999; 
    }
  
    .logo-container {
      flex: 0 0 auto;
    }
  
    .logo {
      height: 30px;
      width: 100px;
    }
  
    .menu {
      display: flex;
      align-items: center;
    }
  
  
    .nav-container ul {
      list-style: none;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      margin: 0;
    }
  
    .nav-container li {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .nav-container .user-icon {
      display: flex;
      align-items: center;
      color: black;
      cursor: pointer;
    }
  
    .nav-container .user-icon span {
      margin-left: 0.5rem;
    }
  }
  