.main-content {
    flex: 1;
    padding: 40px;
    height: 100vh;
    margin-top: 100px;
    display: flex;
    position: relative;
    left:50%;
    transform: translate(-50%, 0);
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    text-align: center;
}

.main-content h1 {
    font-size: 60px;
    margin-bottom: 20px;
}

.cta-section {
    display: flex; 
    justify-content: center;
    align-items: center; 
    width: auto;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .main-content {
        flex: none;
        margin-top: 100px;
        align-items: center;
        padding: 20px;
    }

    .main-content h1 {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .cta-button {
        padding: 10px 20px;
        font-size: 18px;
    }
}
