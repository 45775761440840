/* forgotPassword.css */
.forgot-password-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border-radius: 5px;
    text-align: center; 
  }
  
  .forgot-password-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  label {
    font-weight: bold;
  }
  
  /* Modify button styles */
  button {
    background-color: black;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: auto; 
  }
  
  .login-link {
    margin-top: 10px
  }