.toolbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  align-items: center;
  background-color: red;
  width: 100vw;
  padding: 5px;
}

.toolbar > * {
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0; 
}

.mobile-toolbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 0.5rem;
  z-index: 1000;
  white-space: nowrap;
}

.mobile-toolbar > * {
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0; /* Ensure the flex items shrink properly */
}

.mobile-toolbar .MuiBottomNavigationAction-root .MuiSvgIcon-root {
  font-size: 2rem; 
}
