/* Base styles */
.toolbar {
  grid-area: toolbar;
}
.main {
  height: 100vh;
  top: 60px;
  grid-area: main;
}
.sidebar {
  grid-area: sidebar;
}
.footer {
  grid-area: footer;
}
.header {
  grid-area: header;
}
.main-default {
  display: flex;
  flex-direction: column;
  grid-area: main-area;
}
.alt-main {
  display: grid;
  height: fit-content;
  grid-template-columns: 25vw 75vw;
  grid-template-rows: auto;
  grid-template-areas: 
    "toolbar toolbar"
    "sidebar main";
}
.first-main {
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: auto;
  grid-template-areas: 
    "header"
    "main-area"
    "footer" ;
}

/* Mobile styles */
@media (max-width: 768px) {
  .alt-main {
    display: flex;
    flex-direction: column;
  }

  .main {
    display: flex;
    flex-direction: column;
  }

  .main-default {
    display: flex;
    flex-direction: column;
  }
  .sidebar {
    display: none;
  }

  .toolbar,.main, .footer, .header {
    width: 100%;
  }
}
